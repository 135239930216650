import $ from 'jquery';

$(document).ready(function() {

    $('.matchHeight').matchHeight();

    $(document).on('click','#sommaire-article a',function(){
        var h = $(this).attr('href');

        $('body,html').animate({
            scrollTop:$(h).offset().top -120
        }, 500);
        return false;
    });

    if ($("main").hasClass("travailler-chez-splio")) {
        $("footer").hide();
    }


    $(window).on('load', function(){
        setTimeout(function(){
            $('.toggle-btn').parent().toggleClass('active').siblings().removeClass('active');
        }, 1000);
    });

	$(document).on('click', '#taxonomy-agences-cat-list a', function () {
		var h = $(this).attr('href');

		$('body,html').animate({
			scrollTop: $(h).offset().top -120
		}, 800);
		return false;
	});




});